import { Box, Chip, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";

export function AssignedFilters({ onFilteredSetor, onFiltredToMe }) {
  const [selecteds, setSelecteds] = useState([]);
  const [selectedsSetores, setSelectedsSetores] = useState([]);

  const onChange = async (value) => {
    if (!!value) {
      setSelecteds(value);
      onFiltredToMe(value);
      return
    }
    onFiltredToMe([]);

    setSelecteds([])
  };

  const onChangeSetor = async (value) => {
    if (!!value) {
      setSelectedsSetores(value);
      onFilteredSetor(value);
      return
    }
    onFilteredSetor([]);
    setSelectedsSetores([])

  };

  return (
    <Box style={{ padding: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            size="small"
            options={[{ name: "Abertos", id: 'open' }, { name: "Pendentes", id: 'pending' }, { name: "Fechados", id: 'closed' }]}
            value={selectedsSetores}
            onChange={(e, v, r) => onChangeSetor(v)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Status"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            size="small"
            options={[{ name: "Sim", id: 2 }, { name: "Não", id: 1 }]}
            value={selecteds}
            onChange={(e, v, r) => onChange(v)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Para Mim"
              />
            )}
          />

        </Grid>


      </Grid>
    </Box>
  );
}
