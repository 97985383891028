import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@material-ui/core";
import { MoreVert, PersonAdd, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UndoRoundedIcon from '@material-ui/icons/UndoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { green } from '@material-ui/core/colors';
import Autocomplete from "@material-ui/lab/Autocomplete";


const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

const TicketActionButtonsCustom = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [openAddPeople, setOpenAddPeople] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);

	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const { setCurrentTicket } = useContext(TicketsContext);

	const [users, setUsers] = useState([]);

	useEffect(() => {
		async function fetchData() {
			await loadUsers();
		}
		fetchData();
	}, []);

	const loadUsers = async () => {
		try {
			const { data } = await api.get(`/users/list`);
			const userList = data.map((u) => ({ id: u.id, name: u.name }));


			setUsers(userList.filter((u) => u.id != user.id));
		} catch (err) {
			toastError(err);
		}
	};

	const customTheme = createTheme({
		palette: {
			primary: green,
		}
	});

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
				useIntegration: status === "closed" ? false : ticket.useIntegration,
				promptId: status === "closed" ? false : ticket.promptId,
				integrationId: status === "closed" ? false : ticket.integrationId
			});

			setLoading(false);
			if (status === "open") {
				setCurrentTicket({ ...ticket, code: "#open" });
			} else {
				setCurrentTicket({ id: null, code: null })
				history.push("/tickets");
			}
		} catch (err) {

			setLoading(false);
			toastError(err);
		}
	};

	const handleAddParticipante = async () => {
		setLoading(true)

		const message = {
			read: 1,
			fromMe: true,
			mediaUrl: "",
			body: `_*${user.name}*_ adicionou _*${selectedUsers.name}*_ como participante a esta conversa.`,
			quotedMsg: false,
		};

		try {
			await api.post(`/messages/${ticket.id}`, message);

			setLoading(false);

		} catch (error) {
			setLoading(false);
			toastError(error);
		} finally {
			setOpenAddPeople(false);
			setSelectedUsers([])
		}
	}

	return (
		<div className={classes.actionButtons}>
			<>
				<Tooltip title="Adicionar Participante">
					<IconButton onClick={e => setOpenAddPeople(true)}>
						<PersonAdd />
					</IconButton>
				</Tooltip>

			</>
			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<Tooltip title={i18n.t("messagesList.header.buttons.return")}>
						<IconButton onClick={e => handleUpdateTicketStatus(e, "pending", null)}>
							<UndoRoundedIcon />
						</IconButton>
					</Tooltip>
					<ThemeProvider theme={customTheme}>
						<Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
							<IconButton onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)} color="primary">
								<CheckCircleIcon />
							</IconButton>
						</Tooltip>
					</ThemeProvider>
					{/* <ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner> */}
					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}

			<Dialog
				sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
				maxWidth="xs"
				fullWidth
				open={openAddPeople}
			>
				<DialogTitle>Adicionar Usuário</DialogTitle>
				<DialogContent dividers>
					<Autocomplete
						size="small"
						options={users}
						value={selectedUsers}
						onChange={(e, v, r) => setSelectedUsers(v)}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder="Usuários"
							/>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={() => { setOpenAddPeople(false) }}>
						Cancelar
					</Button>
					<Button onClick={handleAddParticipante}>Adicionar</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default TicketActionButtonsCustom;
