
import { API_URI, HEADER } from "../../utils/constants";

const useApi = (basePath = API_URI) => {

    const get = async (url = '') => {
        let header = HEADER;

        const options = {
            headers: header,
            method: 'GET'
        }

        const response = await fetch(basePath + url, options);
        const data = await response.json();

        return { data, response };
    }

    const post = async (url = '', formData = {}) => {
        let header = HEADER;

        const options = {
            headers: header,
            method: 'POST',
            body: JSON.stringify(formData)
        }

        const response = await fetch(basePath + url, options);
        const data = await response.json();

        return { data, response };
    }

    return { get, post };
}

export default useApi;