import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
  CircularProgress,
  Button,
  Divider
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import api from "../../services/api";
import ModalImageCors from "../../components/ModalImageCors";
import whatsBackground from "../../assets/wa-background.png";
import whatsBackgroundDark from "../../assets/wa-background-dark.png"; //DARK MODE PLW DESIGN//
import { GetApp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "hidden",
    borderRadius: 0,
    height: "100%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  },
  messageList: {
    position: "relative",
    overflowY: "auto",
    height: "100%",
    ...theme.scrollbarStyles,
    backgroundImage: theme.mode === 'light' ? `url(${whatsBackground})` : `url(${whatsBackgroundDark})`, //DARK MODE PLW DESIGN//
  },
  inputArea: {
    position: "relative",
    height: "auto",
  },
  input: {
    padding: "20px",
  },
  buttonSend: {
    margin: theme.spacing(1),
  },
  boxLeft: {
    padding: "10px 10px 5px",
    margin: "10px",
    position: "relative",
    backgroundColor: "#FFFFFF",
    maxWidth: 300,
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  boxRight: {
    padding: "10px 10px 5px",
    margin: "10px 10px 10px auto",
    position: "relative",
    backgroundColor: "#dcf8c6", //DARK MODE PLW DESIGN//
    textAlign: "right",
    maxWidth: 300,
    borderRadius: 10,
    borderBottomRightRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },
  sendMessageIcons: {
    color: "grey",
  },
  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

}));

export default function ChatMessages({
  chat,
  messages,
  handleSendMessage,
  handleLoadMore,
  scrollToBottomRef,
  pageInfo,
  loading,
  handleSendFile
}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const baseRef = useRef();
  const [medias, setMedias] = useState([])
  const [contentMessage, setContentMessage] = useState("");

  useEffect(() => {
    if (unreadMessages(chat) > 0) {
      try {
        api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) { }
    }
    scrollToBottomRef.current = scrollToBottom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToBottom = () => {
    if (baseRef.current) {
      baseRef.current.scrollIntoView({});
    }
  };

  const unreadMessages = (chat) => {
    if (chat !== undefined) {
      const currentUser = chat.users.find((u) => u.userId === user.id);
      return currentUser.unreads > 0;
    }
    return 0;
  };



  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;
    if (!pageInfo.hasMore || loading) return;
    if (scrollTop < 600) {
      handleLoadMore();
    }
  };

  const handleUploadMedia = async e => {
    e.preventDefault();

    let arquivos = new FormData();

    medias.forEach((item, index) => {
      arquivos.append("medias", item);
    })

    handleSendFile(arquivos)
    setMedias([]);
  };

  const handleChangeMedias = e => {
    if (!e.target.files) {
      return;
    }

    setMedias(Array.from(e.target.files));
  };

  const checkMessageMedia = (message) => {
    if (message.mimeType === "image/png" || message.mimeType === "image/jpg" || message.mimeType === "image/jpeg") {

      return <ModalImageCors imageUrl={message.link} />;
    } else if (message.mimeType === "audio/mp3" || message.mimeType === "audio/ogg" || message.mimeType === "audio/mpeg") {
      return (
        <div className={classes.downloadMedia}>
          <audio controls>
            <source src={message.link} type="audio/ogg"></source>
          </audio>
        </div>
      );
    } else if (message.mimeType === "video/mp4") {
      return (
        <video
          className={classes.messageMedia}
          src={message.link}
          controls
        />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message.link}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  return (
    <Paper className={classes.mainContainer}>
      <div onScroll={handleScroll} className={classes.messageList}>
        {Array.isArray(messages) &&
          messages.map((item, key) => {
            if (item.senderId === user.id && !item.isFile) {
              return (
                <Box key={key} className={classes.boxRight}>
                  <Typography variant="subtitle2">
                    {item.sender.name}
                  </Typography>
                  {item.message}
                  <Typography variant="caption" display="block">
                    {datetimeToClient(item.createdAt)}
                  </Typography>
                </Box>
              );
            } else if (!!item.isFile) {
              return (
                <Box key={key} className={item.senderId === user.id ? classes.boxRight : classes.boxLeft}>
                  <Typography variant="subtitle2">
                    {item.sender.name}
                  </Typography>
                  {checkMessageMedia(item)}
                  {item.message}
                  <Typography variant="caption" display="block">
                    {datetimeToClient(item.createdAt)}
                  </Typography>
                </Box>
              )
            }
            else {
              return (
                <Box key={key} className={classes.boxLeft}>
                  <Typography variant="subtitle2">
                    {item.sender.name}
                  </Typography>
                  {item.message}
                  <Typography variant="caption" display="block">
                    {datetimeToClient(item.createdAt)}
                  </Typography>
                </Box>
              );
            }
          })}
        <div ref={baseRef}></div>
      </div>
      <div className={classes.inputArea}>
        {!!medias && medias.length > 0 ?
          <>
            <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
              <IconButton
                aria-label="cancel-upload"
                component="span"
                onClick={e => setMedias([])}
              >
                <CancelIcon className={classes.sendMessageIcons} />
              </IconButton>

              {loading ? (
                <div>
                  <CircularProgress className={classes.circleLoading} />
                </div>
              ) : (
                <span>
                  {medias[0]?.name}
                  {/* <img src={media.preview} alt=""></img> */}
                </span>
              )}
              <IconButton
                aria-label="send-upload"
                component="span"
                onClick={handleUploadMedia}
                disabled={loading}
              >
                <SendIcon className={classes.sendMessageIcons} />
              </IconButton>
            </Paper>
          </>
          :
          <>
            <FormControl variant="outlined" fullWidth>
              <Input
                multiline
                value={contentMessage}
                onKeyUp={(e) => {
                  if (e.key === "Enter" && contentMessage.trim() !== "") {
                    handleSendMessage(contentMessage);
                    setContentMessage("");
                  }
                }}
                onChange={(e) => setContentMessage(e.target.value)}
                className={classes.input}
                startAdornment={
                  <InputAdornment position="start">
                    <input
                      multiple
                      type="file"
                      id="upload-button"
                      disabled={loading} //AJUSTAR PARA UPLOAD PROGRESS
                      style={{ display: "none" }}
                      onChange={handleChangeMedias}
                    />
                    <label htmlFor="upload-button">
                      <IconButton
                        aria-label="upload"
                        component="span"
                        disabled={loading} //AJUSTAR PARA UPLOAD PROGRESS
                      >
                        <AttachFileIcon className={classes.buttonSend} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        if (contentMessage.trim() !== "") {
                          handleSendMessage(contentMessage);
                          setContentMessage("");
                        }
                      }}
                      className={classes.buttonSend}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </>
        }


      </div>
    </Paper>
  );
}
