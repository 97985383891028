import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import logo from "../../assets/logo.png";
import LoginPageBG from "../../assets/bg-page-account.png";

import { Paper } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";


const useStyles = makeStyles(theme => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundImage: `url("${LoginPageBG}")`,
        backgroundRepeat: "no-repeat",

        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rootLeft: {
        width: '50%',
        height: "100vh",        
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    rootRight: {
        width: '25%',
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        gap: "2rem",
        '@media (max-width: 768px)': {
            width: '50%'
        }
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "55px 30px",
        borderRadius: "12.5px",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {

    },
    powered: {
        color: "white"
    }
}));

const LoginCustom = () => {
    const classes = useStyles();

    const [user, setUser] = useState({ email: "", password: "" });

    const { handleLogin } = useContext(AuthContext);

    const handleChangeInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handlSubmit = e => {
        e.preventDefault();
        handleLogin(user);
    };

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <CssBaseline />
            <div className={classes.root}>
                <div className={classes.rootLeft}></div>
                <div className={classes.rootRight}>
                    <Paper className={classes.paper} elevation={3} variant="outlined" square>
                        <div>
                            <center>
                                <img style={{ margin: "0 auto", width: "70%" }} src={logo} alt="SwEasy" />
                            </center>
                        </div>

                        <form className={classes.form} noValidate onSubmit={handlSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={i18n.t("login.form.email")}
                                name="email"
                                value={user.email}
                                onChange={handleChangeInput}
                                autoComplete="email"
                                autoFocus
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={i18n.t("login.form.password")}
                                type="password"
                                id="password"
                                value={user.password}
                                onChange={handleChangeInput}
                                autoComplete="current-password"
                            />

                            <Grid container spacing={2} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <Grid item xs={12} style={{ textAlign: 'left' }}>
                                    <Link
                                        href="#"
                                        variant="body2"
                                        component={RouterLink}
                                        to="/forgetpsw"
                                    >
                                        {i18n.t("Esqueci minha senha")}
                                    </Link>
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                style={{ borderRadius: '20px' }}
                            >
                                {i18n.t("login.buttons.submit")}
                            </Button>

                            <Grid container spacing={2} style={{ marginTop: '1rem', textAlign: 'center' }}>
                                <Grid item xs={12}>
                                    <Link
                                        href="#"
                                        variant="body2"
                                        component={RouterLink}
                                        to="/signup"
                                    >
                                        {i18n.t("login.buttons.register")}
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </div>
            </div>
        </>
    );
};

export default LoginCustom;



