import React, { useState, useEffect, useReducer, useCallback, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Badge, Grid, TextField, Tooltip, Paper, Box } from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HistoryIcon from "@material-ui/icons/History";
import { blue } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from 'xlsx';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TicketMessagesDialog from "../../components/TicketMessagesDialog";
import { ConnectionsFilter } from "../../components/ConnectionsFilter";
import { QueueFilter } from "../../components/QueueFilter";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { TicketsContext } from "../../context/Tickets/TicketsContext";

const reducer = (state, action) => {
    if (action.type == "LOAD_TICKETS") {
        const tickets = action.payload;

        if (tickets.length === 0) {
            return [];
        }

        const updatedTickets = tickets.map(ticket => {
            const existingIndex = state.findIndex(s => s.id === ticket.id);
            return existingIndex !== -1 ? { ...state[existingIndex], ...ticket } : ticket;
        });

        return updatedTickets;
    }

    return state;
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    filterPaper: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    margin: {
        margin: theme.spacing.unit * 2
    },
    dangerBadge: {
        backgroundColor: '#d32f2f',
        color: '#fff'
    },
    warningBadge: {
        backgroundColor: '#f57c00',
        color: '#fff'
    },
    successBadge: {
        backgroundColor: '#388e3c',
        color: '#fff'
    },
    fullWidth: {
        width: '100%'
    },
    button: {
        background: "#d32f2f",
        border: "none",
        padding: "10px",
        color: "white",
        fontWeight: "bold",
        borderRadius: "5px",
    }
}));

const RelatorioTickets = () => {
    const classes = useStyles();

    const [load, setLoad] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [ticketId, setTicketId] = useState(false);
    const [tickets, dispatch] = useReducer(reducer, []);
    const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
    const { setCurrentTicket } = useContext(TicketsContext);

    const [filter, setFilter] = useState({
        ticket: '',
        connections: [],
        queues: [],
        cliente: '',
        usuario: '',
        status: [],
        tipoData: [],
        dataInicial: '',
        dataFinal: ''
    });

    const fetchRelatorioTickets = useCallback(async () => {
        let params = {
            ...filter,
            tipoData: (filter.tipoData ? filter.tipoData.id : []),
            status: (filter.status ? filter.status.id : []),
            pageNumber
        };

        try {
            const { data } = await api.get("/ticket/relatorio/", { params });

            dispatch({ type: "LOAD_TICKETS", payload: data.tickets });
            setHasMore(data.hasMore);
            setLoad(false);
        } catch (err) {
            toastError(err);
        }
    }, [pageNumber, filter]);

    useEffect(() => {
        setLoad(true);
        const delayDebounceFn = setTimeout(() => {
            fetchRelatorioTickets();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [pageNumber, fetchRelatorioTickets]);

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || load) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const onChangeStatus = async (value) => {
        if (value) {
            setFilter((prev) => {
                return { ...prev, status: value };
            });
        } else {
            setFilter((prev) => {
                return { ...prev, status: [] };
            });
        }
    };

    const onChangeTipoData = async (value) => {
        if (value) {
            setFilter((prev) => {
                return { ...prev, tipoData: value };
            });
        } else {
            setFilter((prev) => {
                return { ...prev, tipoData: [] };
            });
        }
    };

    const updateConnections = (connections) => {
        const ids = connections.map(connection => connection.id);
        setFilter(prevFilter => ({
            ...prevFilter,
            connections: ids
        }));
    };

    const updateQueues = (queues) => {
        const ids = queues.map(queue => queue.id);
        setFilter(prevFilter => ({
            ...prevFilter,
            queues: ids
        }));
    };

    const handleSelectTicket = (ticket) => {
        const code = uuidv4();
        const { id, uuid } = ticket;
        setCurrentTicket({ id, uuid, code });
    };

    const exportToXlsx = () => {
        const data = tickets.map(ticket => ({
            Ticket: ticket.id,
            Conexão: ticket.whatsapp ? ticket.whatsapp.name : '-',
            Cliente: ticket.contact ? ticket.contact.name : '-',
            Usuário: ticket.user ? ticket.user.name : '-',
            Fila: ticket.queue ? ticket.queue.name : '-',
            Status: ticket.status === "closed" ? "Fechado" : ticket.status === "pending" ? "Pendente" : "Aberto",
            'Data Abertura': ticket.createdAt ? moment(ticket.createdAt).format("DD/MM/YYYY") : '-',
            'Data Fechamento': ticket.updatedAt ? moment(ticket.updatedAt).format("DD/MM/YYYY") : '-',
        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Tickets");

        XLSX.writeFile(wb, "relatorio-tickets.xlsx");
    };

    return (
        <MainContainer>
            <TicketMessagesDialog
                open={openTicketMessageDialog}

                handleClose={() => setOpenTicketMessageDialog(false)}
                ticketId={ticketId}
            ></TicketMessagesDialog>

            <MainHeader>
                <Title>{i18n.t("relatorioTickets.title")}</Title>
            </MainHeader>

            {/* Filters */}
            <Paper
                className={classes.filterPaper}
                variant="outlined"
                onScroll={handleScroll}
                style={{ padding: '1rem' }}
            >
                <Grid spacing={2} container>
                    <Grid xs={12} sm={6} md={3} item>
                        <Autocomplete
                            size="small"
                            options={[{ name: "Data Abertura", id: 'DataAbertura' }, { name: "Data Fechamento", id: 'DataFechamento' }]}
                            value={filter.tipoData}
                            onChange={(e, v, r) => onChangeTipoData(v)}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Tipo Data"
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} item>
                        <TextField
                            type="date"
                            label="Data Inicial"
                            value={filter.dataInicial}
                            onChange={(e) => setFilter({ ...filter, dataInicial: e.target.value })}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} item>
                        <TextField
                            type="date"
                            label="Data Final"
                            value={filter.dataFinal}
                            onChange={(e) => setFilter({ ...filter, dataFinal: e.target.value })}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} item>
                        <TextField
                            type="number"
                            label="Ticket"
                            placeholder="Ticket"
                            value={filter.ticket}
                            onChange={(e) => setFilter({ ...filter, ticket: e.target.value })}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} item>
                        <ConnectionsFilter
                            onFiltered={(connections) => updateConnections(connections)}
                            initialUsers={filter.connections}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} item>
                        <TextField
                            type="text"
                            label="Cliente"
                            placeholder="Cliente"
                            value={filter.cliente}
                            onChange={(e) => setFilter({ ...filter, cliente: e.target.value })}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} item>
                        <TextField
                            type="text"
                            label="Usuário"
                            placeholder="Usuário"
                            value={filter.usuario}
                            onChange={(e) => setFilter({ ...filter, usuario: e.target.value })}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} item>
                        <QueueFilter
                            onFiltered={(queue) => updateQueues(queue)}
                            initialUsers={filter.queues}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} item>
                        <Autocomplete
                            size="small"
                            options={[{ name: "Aberto", id: 'open' }, { name: "Pendente", id: 'pending' }, { name: "Fechado", id: 'closed' }]}
                            value={filter.status}
                            onChange={(e, v, r) => onChangeStatus(v)}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Status"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {/* Table */}
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Box style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        startIcon={<GetApp />}
                        onClick={exportToXlsx}
                        color="primary"
                        variant="outlined"
                        style={{ marginBottom: '1rem' }}
                        title="Exportar Excel"
                    >
                        Exportar
                    </Button>
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Ticket</TableCell>
                            <TableCell>Conexão</TableCell>
                            <TableCell>Cliente</TableCell>
                            <TableCell>Usuário</TableCell>
                            <TableCell>Fila</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell>Data Abertura</TableCell>
                            <TableCell>Data Fechamento</TableCell>
                            <TableCell align="right">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {tickets.map((ticketList) => (
                                <>
                                    <TableRow key={ticketList.id}>
                                        <TableCell align="center">{ticketList.id}</TableCell>
                                        <TableCell align="left">{ticketList.whatsapp ? ticketList.whatsapp.name : '-'}</TableCell>
                                        <TableCell align="left">{ticketList.contact ? ticketList.contact.name : '-'}</TableCell>
                                        <TableCell align="left">{ticketList.user ? ticketList.user.name : '-'}</TableCell>
                                        <TableCell align="left">{ticketList.queue ? ticketList.queue.name : '-'}</TableCell>
                                        <TableCell align="center">
                                            {ticketList.status === "closed" ? (
                                                <Badge
                                                    className={classes.margin}
                                                    classes={{ badge: classes.dangerBadge }}
                                                    badgeContent={"Fechado"}
                                                />
                                            ) : (
                                                ticketList.status === "pending" ? (
                                                    <Badge
                                                        className={classes.margin}
                                                        classes={{ badge: classes.warningBadge }}
                                                        badgeContent={"Pendente"}
                                                    />
                                                ) : (
                                                    ticketList.status === "open" && (
                                                        <Badge
                                                            className={classes.margin}
                                                            classes={{ badge: classes.successBadge }}
                                                            badgeContent={"Aberto"}
                                                        />
                                                    )
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell>{ticketList.createdAt ? moment(ticketList.createdAt).format("DD/MM/YYYY") : '-'}</TableCell>
                                        <TableCell>{ticketList.updatedAt ? moment(ticketList.updatedAt).format("DD/MM/YYYY") : '-'}</TableCell>
                                        <TableCell align="right">
                                            <Tooltip title="Espiar Conversa">
                                                <VisibilityIcon
                                                    onClick={() => { setTicketId(ticketList.id); setOpenTicketMessageDialog(true); }}
                                                    fontSize="small"
                                                    style={{
                                                        color: blue[700],
                                                        cursor: "pointer",
                                                        marginLeft: 10,
                                                        verticalAlign: "middle"
                                                    }}
                                                />
                                            </Tooltip>
                                            {ticketList.status !== 'pending' &&
                                                <Tooltip title="Acessar Ticket">
                                                    <HistoryIcon
                                                        onClick={() => handleSelectTicket(ticketList)}
                                                        fontSize="small"
                                                        style={{
                                                            color: blue[700],
                                                            cursor: "pointer",
                                                            marginLeft: 10,
                                                            verticalAlign: "middle"
                                                        }}
                                                    />
                                                </Tooltip>
                                            }

                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                            {load && <TableRowSkeleton columns={9} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default RelatorioTickets;
