import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { CloseOutlined, GetAppOutlined } from "@material-ui/icons";
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from "@material-ui/core";

import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	messageMedia: {
		objectFit: "cover",
		width: 250,
		height: 200,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
	image: {
		objectFit: "cover",
		objectPosition: "center center"
	}
}));

const ModalImageCors = ({ imageUrl }) => {
	const classes = useStyles();
	const [fetching, setFetching] = useState(true);
	const [blobUrl, setBlobUrl] = useState("");
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!imageUrl) return;
		const fetchImage = async () => {
			const { data, headers } = await api.get(imageUrl, {
				responseType: "blob",
			});
			const url = window.URL.createObjectURL(
				new Blob([data], { type: headers["content-type"] })
			);
			setBlobUrl(url);
			setFetching(false);
		};
		fetchImage();
	}, [imageUrl]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<img src={imageUrl} className={classes.image} width={250} height={200} onClick={() => setOpen(!open)} />

			<Dialog
				open={open}
				onClose={handleClose}
				fullScreen
			>
				<DialogContent>
					<AppBar>
						<Toolbar>
							<Typography variant="h6" className={classes.title}>
								Image
							</Typography>

							<div style={{ marginLeft: "auto" }}>
								<IconButton edge="start" color="inherit" onClick={() => window.open(imageUrl)} aria-label="close">
									<GetAppOutlined />
								</IconButton>

								<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
									<CloseOutlined />
								</IconButton>
							</div>
						</Toolbar>
					</AppBar>

					<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height:"100%" }}>
						<img src={imageUrl} className={classes.image} height={550} />
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ModalImageCors;
