import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import * as yup from "yup"

import { toast } from "react-toastify";

import { AppBar, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import { maskCEP, maskCPF, maskCnpj, maskTelefone, numberFormat, removerMascara } from "../../utils/functions";

import Container from "@material-ui/core/Container";
import logo from "../../assets/logo.png";
import useApi from "../../hooks/useApi";
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		height: 'calc(100vh - 150px)',
		alignItems: 'strach',
		gap: '2rem'
	},
	rootLeft: {
		width: '65%',
	},
	rootLeftPaper: {
		padding: "20px",
		marginBottom: '1rem'
	},
	rootRight: {
		width: '35%',
	},
	rootRightPaper: {
		padding: "20px",
	},
	logo: {
		paddingTop: '1rem',
		paddingBottom: '1rem',
	},
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const useHelperTextStyles = makeStyles(theme => ({
	root: {
		color: theme.palette.primary.main,
		padding: 0,
		margin: 0
	}
}));

const schema = yup.object({
	ServicosId: yup.string().required("Informe o plano!"),
	Individuos: yup.object({
		TipoPessoa: yup.string().required("Informe o tipo de pessoa!"),
		Nome: yup.string().required("Informe o Nome"),
		Documento: yup.string().when('TipoPessoa', (TipoPessoa, schema) => {
			if (parseInt(TipoPessoa) === 2) {
				return schema.transform((value) => removerMascara(value.replace('_', ''))).length(14, 'Informe um CNPJ válido').required('Informe o CNPJ');
			} else if (parseInt(TipoPessoa) === 1) {
				return schema.transform((value) => removerMascara(value.replace('_', ''))).length(11, 'Informe um CPF válido').required('Informe o CPF');
			}
		}),
		InscricaoMunicipal: yup.string(),
		InscricaoEstadual: yup.string(),
		NomeFantasia: yup.string().when('TipoPessoa', (TipoPessoa, schema) => {
			if (parseInt(TipoPessoa) === 2) {
				return schema.required('Informe o nome fantasia!');
			}
		}),
		DataNascimento: yup.string().when('TipoPessoa', (TipoPessoa, schema) => {
			if (parseInt(TipoPessoa) === 1) {
				return schema.required('Informe a data de nascimento!');
			}
		}),
		Email: yup.string().required("Informe o E-mail!").email('E-mail inválido!'),
		IndividuosContatos: yup.object().shape({
			Celular: yup.string().required("Informe o Número do Celular!"),
			Telefone: yup.string(),
		}),
		IndividuosEnderecos: yup.object().shape({
			Cep: yup.string().required("Informe o Cep"),
			Logradouro: yup.string().required("Informe o Logradouro"),
			Numero: yup.string().required("Informe o Número"),
			Bairro: yup.string().required("Informe o Bairro"),
			EstadosId: yup.string().required("Informe o Estado!").nullable(),
			CidadesId: yup.string().required("Informe a Cidade!").nullable(),
		})
	})
});

const SignUp = () => {
	const api = useApi();
	const classes = useStyles();
	const helperTextStyles = useHelperTextStyles();

	const [planos, setPlanos] = useState([]);
	const [estados, setEstados] = useState([]);
	const [cidades, setCidades] = useState([]);
	const [btnBisabled, setBtnBisabled] = useState(false)
	const [fatura, setFatura] = useState('')

	const { register, handleSubmit, watch, setValue, reset, formState: { errors }, } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			Individuos: {
				TipoPessoa: '1'
			}
		}
	});

	const { Individuos, ServicosId } = watch();

	useEffect(() => {
		setValue('Individuos.IndividuosContatos.Celular', maskTelefone(watch('Individuos.IndividuosContatos.Celular')))
	}, [watch('Individuos.IndividuosContatos.Celular')])

	useEffect(() => {
		setValue('Individuos.IndividuosContatos.Telefone', maskTelefone(watch('Individuos.IndividuosContatos.Telefone')))
	}, [watch('Individuos.IndividuosContatos.Telefone')])

	useEffect(() => {
		setValue('Individuos.IndividuosContatos.Cep', maskCEP(watch('Individuos.IndividuosContatos.Cep')))
	}, [watch('Individuos.IndividuosContatos.Cep')])

	useEffect(() => {
		if (parseInt(Individuos.TipoPessoa) == 1) {
			setValue('Individuos.Documento', maskCPF(Individuos.Documento))
		} else {
			setValue('Individuos.Documento', maskCnpj(Individuos.Documento))
		}
	}, [watch('Individuos.Documento')])

	const onSubmit = formData => {
		const data = formData.Individuos;
		const dataToSend = data;

		if (data.IndividuosContatos.Telefone != '') {
			dataToSend.IndividuosContatos = [
				{
					Ddd: data.IndividuosContatos.Celular.substr(1, 2),
					Telefone: data.IndividuosContatos.Celular.substr(4).replace('-', '').trim(),
					Whatsapp: 1,
					TipoContato: 1
				},
				{
					Ddd: data.IndividuosContatos.Telefone.substr(1, 2),
					Telefone: data.IndividuosContatos.Telefone.substr(4).replace('-', '').trim(),
					Whatsapp: 0,
					TipoContato: 2
				}
			];
		} else {
			dataToSend.IndividuosContatos = [
				{
					Ddd: data.IndividuosContatos.Celular.substr(1, 2),
					Telefone: data.IndividuosContatos.Celular.substr(4).replace('-', '').trim(),
					Whatsapp: 1,
					TipoContato: 1
				}
			];
		}

		dataToSend.IndividuosEnderecos = [
			{
				...data.IndividuosEnderecos,
				PontoReferencia: ''
			}
		];

		dataToSend.IndividuosEnderecos[0].Cep = dataToSend.IndividuosEnderecos[0].Cep.replace('-', '');

		setBtnBisabled(true);

		api.post('Vendas/VendaSite', { ServicosId: formData.ServicosId, Individuos: dataToSend })
			.then((res) => {
				if (res.data.erro) {
					toast.error(res.data.erro);
					return;
				}

				reset();

				setFatura(res.data.UrlFatura);
				setBtnBisabled(true)


				swal({
					title: "Seu cadastro foi realizado com sucesso!",
					text: 'Para ativar a sua conta você precisa efetuar o pagamento do boleto.',
					icon: "success",
					closeOnEsc: false,
					closeOnClickOutside: false,
					cancelButtonColor: '#AC282E',
					button: {
						text: "Baixar Boleto",
						closeModal: false,
						className: 'btn btn-primary',

					},
				}).then(() => {
					window.open(res.data.UrlFatura);
					setTimeout(() => window.location.href = "/", 2000);
				});
			});
	}

	useEffect(() => {
		let request = true;

		if (request) {
			api.get('Servicos/getDisponiveis')
				.then(res => setPlanos(res.data));

			api.get('Estados/consultar/?order=Nome')
				.then(res => setEstados(res.data));
		}

		return () => {
			request = false;
		}

	}, [])

	useEffect(() => {
		_getCidades();
	}, [watch('Individuos.IndividuosEnderecos.EstadosId')])

	const _getCidades = (cidadeId = '') => {
		if (Individuos && Individuos.IndividuosEnderecos && Individuos.IndividuosEnderecos.EstadosId) {
			api.get(`Cidades/consultar/?order=Nome&EstadosId=${Individuos.IndividuosEnderecos.EstadosId}`)
				.then(res => {
					setCidades(res.data);
					if (cidadeId != '')
						setValue('Individuos.IndividuosEnderecos.CidadesId', cidadeId);
				});
		}
	}

	const handleSearchCep = (cep) => {
		api.post(`Enderecos/ConsultarCep`, { Cep: cep })
			.then(res => {
				if (res.data.erro) {
					toast.error('Dados do CEP não encontrato.');
					return;
				}

				setValue('Individuos.IndividuosEnderecos.Logradouro', res.data.logradouro);
				setValue('Individuos.IndividuosEnderecos.Bairro', res.data.bairro);
				setValue('Individuos.IndividuosEnderecos.EstadosId', res.data.estado_id);

				_getCidades(res.data.cidade_id);
			});
	}

	let plano;

	if (ServicosId) {
		plano = planos.filter(item => Number(item.Id) == Number(ServicosId))
	}

	return (
		<>
			<AppBar position="static" style={{ marginBottom: '2rem', backgroundColor: "#fff" }}>
				<Toolbar variant="dense" style={{ justifyContent: 'center' }}>
					<div className={classes.logo}>
						<Link to="/">
							<img src={logo} width="150" alt="SwEasy" />
						</Link>
					</div>
				</Toolbar>
			</AppBar>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Container className={classes.root}>
					<div className={classes.rootLeft}>

						<Paper elevation={3} className={classes.rootLeftPaper}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={4}>
									<TextField
										select
										label="Plano"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('ServicosId')}
										SelectProps={{ native: true }}
										helperText={errors.ServicosId && errors.ServicosId.message ? errors.ServicosId.message : ''}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}
									>
										<option value="">- Selecione -</option>
										{planos.length > 0 && planos.map((item, i) => (
											<option value={item.Id} key={i}>{item.Nome} R$ {item.ValorUnitario}</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Paper>

						<Paper elevation={3} className={classes.rootLeftPaper}>
							<Grid container spacing={2} style={{ marginBottom: '1rem' }}>
								<Grid item xs={12} sm={4}>
									<TextField
										select
										label="Tipo Pessoa"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.TipoPessoa')}
										SelectProps={{ native: true }}
										helperText={errors.Individuos && errors.Individuos.TipoPessoa && errors.Individuos.TipoPessoa.message ? errors.Individuos.TipoPessoa.message : ''}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}
									>
										<option value="1">Pessoal Física</option>
										<option value="2">Pessoal Jurídica</option>
									</TextField>
								</Grid>
							</Grid>

							{watch('Individuos.TipoPessoa') == 1 &&
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4}>
										<TextField
											label="Nome"
											variant="outlined"
											size="small"
											fullWidth
											InputLabelProps={{ shrink: true }}
											{...register('Individuos.Nome')}
											helperText={errors.Individuos && errors.Individuos.Nome && errors.Individuos.Nome.message ? errors.Individuos.Nome.message : ''}
											FormHelperTextProps={{
												classes: {
													root: helperTextStyles.root
												}
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											label="CPF"
											variant="outlined"
											size="small"
											fullWidth
											InputLabelProps={{ shrink: true }}
											{...register('Individuos.Documento')}
											helperText={errors.Individuos && errors.Individuos.Documento && errors.Individuos.Documento.message ? errors.Individuos.Documento.message : ''}
											FormHelperTextProps={{
												classes: {
													root: helperTextStyles.root
												}
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											type="date"
											label="Data Nascimento"
											variant="outlined"
											size="small"
											fullWidth
											InputLabelProps={{ shrink: true }}
											{...register('Individuos.DataNascimento')}
											helperText={errors.Individuos && errors.Individuos.DataNascimento && errors.Individuos.DataNascimento.message ? errors.Individuos.DataNascimento.message : ''}
											FormHelperTextProps={{
												classes: {
													root: helperTextStyles.root
												}
											}}
										/>
									</Grid>
								</Grid>
							}

							{watch('Individuos.TipoPessoa') == 2 &&
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4}>
										<TextField
											label="Razão Social"
											variant="outlined"
											size="small"
											fullWidth
											InputLabelProps={{ shrink: true }}
											{...register('Individuos.Nome')}
											helperText={errors.Individuos && errors.Individuos.Nome && errors.Individuos.Nome.message ? errors.Individuos.Nome.message : ''}
											FormHelperTextProps={{
												classes: {
													root: helperTextStyles.root
												}
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											label="Nome Fantasia"
											variant="outlined"
											size="small"
											fullWidth
											InputLabelProps={{ shrink: true }}
											{...register('Individuos.NomeFantasia')}
											helperText={errors.Individuos && errors.Individuos.NomeFantasia && errors.Individuos.NomeFantasia.message ? errors.Individuos.NomeFantasia.message : ''}
											FormHelperTextProps={{
												classes: {
													root: helperTextStyles.root
												}
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											label="CNPJ"
											variant="outlined"
											size="small"
											fullWidth
											InputLabelProps={{ shrink: true }}
											{...register('Individuos.Documento')}
											helperText={errors.Individuos && errors.Individuos.Documento && errors.Individuos.Documento.message ? errors.Individuos.Documento.message : ''}
											FormHelperTextProps={{
												classes: {
													root: helperTextStyles.root
												}
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											label="Inscrição Municipal"
											variant="outlined"
											size="small"
											fullWidth
											InputLabelProps={{ shrink: true }}
											{...register('Individuos.InscricaoMunicipal')}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											label="Inscrição Estadual"
											variant="outlined"
											size="small"
											fullWidth
											InputLabelProps={{ shrink: true }}
											{...register('Individuos.InscricaoEstadual')}
										/>
									</Grid>
								</Grid>
							}
						</Paper>

						<Paper elevation={3} className={classes.rootLeftPaper}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={4}>
									<TextField
										label="E-mail"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.Email')}
										helperText={errors.Individuos && errors.Individuos.Email && errors.Individuos.Email.message ? errors.Individuos.Email.message : ''}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}

									/>
								</Grid>

								<Grid item xs={12} sm={4}>
									<TextField
										label="Celular"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.IndividuosContatos.Celular')}
										helperText={errors.Individuos && errors.Individuos.IndividuosContatos && errors.Individuos.IndividuosContatos.Celular && errors.Individuos.IndividuosContatos.Celular.message ? errors.Individuos.IndividuosContatos.Celular.message : ''}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={4}>
									<TextField
										label="Telefone"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.IndividuosContatos.Telefone')}
									/>
								</Grid>
							</Grid>
						</Paper>

						<Paper elevation={3} className={classes.rootLeftPaper}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={4}>
									<TextField
										label="Cep"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.IndividuosEnderecos.Cep')}
										helperText={errors.Individuos && errors.Individuos.IndividuosEnderecos && errors.Individuos.IndividuosEnderecos.Cep && errors.Individuos.IndividuosEnderecos.Cep.message ? errors.Individuos.IndividuosEnderecos.Cep.message : ''}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}
										InputProps={{
											endAdornment: <InputAdornment size="small" position="end">
												<IconButton onClick={() => handleSearchCep(Individuos.IndividuosEnderecos.Cep || '')}><Search /></IconButton>
											</InputAdornment>,
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={8}>
									<TextField
										label="Logradouro"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.IndividuosEnderecos.Logradouro')}
										helperText={errors.Individuos && errors.Individuos.IndividuosEnderecos && errors.Individuos.IndividuosEnderecos.Logradouro && errors.Individuos.IndividuosEnderecos.Logradouro.message ? errors.Individuos.IndividuosEnderecos.Logradouro.message : ''}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={4}>
									<TextField
										label="Número"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.IndividuosEnderecos.Numero')}
										helperText={errors.Individuos && errors.Individuos.IndividuosEnderecos && errors.Individuos.IndividuosEnderecos.Numero && errors.Individuos.IndividuosEnderecos.Numero.message ? errors.Individuos.IndividuosEnderecos.Numero.message : ''}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={4}>
									<TextField
										label="Bairro"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.IndividuosEnderecos.Bairro')}
										helperText={errors.Individuos && errors.Individuos.IndividuosEnderecos && errors.Individuos.IndividuosEnderecos.Bairro && errors.Individuos.IndividuosEnderecos.Bairro.message ? errors.Individuos.IndividuosEnderecos.Bairro.message : ''}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={4}>
									<TextField
										label="Complemento"
										variant="outlined"
										size="small"
										fullWidth
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.IndividuosEnderecos.Complemento')}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Estado"
										variant="outlined"
										size="small"
										fullWidth
										select
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.IndividuosEnderecos.EstadosId')}
										helperText={errors.Individuos && errors.Individuos.IndividuosEnderecos && errors.Individuos.IndividuosEnderecos.EstadosId && errors.Individuos.IndividuosEnderecos.EstadosId.message ? errors.Individuos.IndividuosEnderecos.EstadosId.message : ''}
										SelectProps={{ native: true }}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}
									>
										<option value="">- Selecione -</option>
										{estados.length > 0 && estados.map((item, i) => (
											<option value={item.Id} key={i}>{item.Nome}</option>
										))}
									</TextField>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Cidade"
										variant="outlined"
										size="small"
										fullWidth
										select
										InputLabelProps={{ shrink: true }}
										{...register('Individuos.IndividuosEnderecos.CidadesId')}
										helperText={errors.Individuos && errors.Individuos.IndividuosEnderecos && errors.Individuos.IndividuosEnderecos.CidadesId && errors.Individuos.IndividuosEnderecos.CidadesId.message ? errors.Individuos.IndividuosEnderecos.CidadesId.message : ''}
										SelectProps={{ native: true }}
										FormHelperTextProps={{
											classes: {
												root: helperTextStyles.root
											}
										}}
									>
										<option value="">- Selecione -</option>
										{cidades.length > 0 && cidades.map((item, i) => (
											<option value={item.Id} key={i}>{item.Nome}</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Paper>

					</div>

					{ServicosId &&
						<div className={classes.rootRight}>
							<Paper elevation={3} className={classes.rootRightPaper}>
								<h3 style={{ marginTop: 0 }}>Detalhes</h3>

								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Produto</TableCell>
											<TableCell style={{ textAlign: 'right' }}>Valor</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>

										<TableRow>
											<TableCell>{plano && plano.length > 0 && plano[0].Nome ? plano[0].Nome : ''}</TableCell>
											<TableCell style={{ textAlign: 'right' }}>
												{plano && plano.length > 0 && plano[0].ValorUnitario ? "R$ " + numberFormat(plano[0].ValorUnitario) : ''}
											</TableCell>
										</TableRow>

									</TableBody>
								</Table>

								<Table>
									<TableBody>
										<TableRow>
											<TableCell style={{ textAlign: 'right' }}>Forma de Pagamento</TableCell>
											<TableCell style={{ textAlign: 'right' }}>Boleto</TableCell>
										</TableRow>
										<TableRow>
											<TableCell style={{ textAlign: 'right' }}>Valor Total</TableCell>
											<TableCell style={{ textAlign: 'right' }}>
												{plano && plano.length > 0 && plano[0].ValorUnitario ? "R$ " + numberFormat(plano[0].ValorUnitario) : ''}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Paper>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={btnBisabled}
								className={classes.submit}
								style={{ borderRadius: '20px' }}
							>
								{btnBisabled &&
									<CircularProgress />
								}

								{!btnBisabled &&
									<>Finalizar Contratação</>
								}
							</Button>
						</div>
					}
				</Container>
			</form>
		</>
	);
};

export default SignUp;
