import React, { useState, useEffect, useReducer, useCallback, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, Box, Grid, TextField } from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { FindInPage, GetApp } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import moment from "moment";
import * as XLSX from 'xlsx';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TicketMessagesDialog from "../../components/TicketMessagesDialog";

import api from "../../services/api";
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    filterPaper: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    margin: {
        margin: theme.spacing.unit * 2
    },
    dangerBadge: {
        backgroundColor: '#d32f2f',
        color: '#fff'
    },
    warningBadge: {
        backgroundColor: '#f57c00',
        color: '#fff'
    },
    successBadge: {
        backgroundColor: '#388e3c',
        color: '#fff'
    },
    fullWidth: {
        width: '100%'
    },
    button: {
        background: "#d32f2f",
        border: "none",
        padding: "10px",
        color: "white",
        fontWeight: "bold",
        borderRadius: "5px",
    }
}));

const RelatorioAtendimentos = () => {
    const classes = useStyles();

    const [load, setLoad] = useState(false);
    const [ticketId, setTicketId] = useState(false);
    const [dataInicial, setDataInicial] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [usuario, setUsuario] = useState("");


    const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
    const [dataRelatorio, setDataRelatorio] = useState([])
    const [dataRelatorioFilter, setDataRelatorioFilter] = useState([])
    const companyId = localStorage.getItem("companyId");

    useEffect(() => {
        if (!!companyId)
            getAtendimentoRelatorio()
    }, [companyId])

    const getAtendimentoRelatorio = async () => {
        setLoad(true)

        const { data } = await api.get("/users/relatorios/list", {
            params: {
                companyId: companyId,
                dataInicial: dataInicial,
                dataFinal: dataFinal,
                nome: usuario
            },
        });

        if (!!data) {
            let dataToSet = data.map((userItem) => { return { ...userItem } })
            setDataRelatorio(dataToSet)
            setDataRelatorioFilter(dataToSet)
        } else {
            setDataRelatorio([])
            setDataRelatorioFilter([])
        }

        setLoad(false)
    }

    function formatTime(minutes) {
        return moment()
            .startOf("day")
            .add(minutes, "minutes")
            .format("HH[h] mm[m]");
    }

    const exportToXlsx = () => {
        const data = dataRelatorioFilter.map(usr => ({
            Agente: usr.nome,
            Iniciados: usr.totalabertos ? usr.totalabertos : '-',
            Finalizados: usr.totalfechados ? usr.totalfechados : '-',
            EnviadosParaFila: usr.totalpendentes ? usr.totalpendentes : '-',
            Transferidos: usr.transferidos ? usr.transferidos : '-',
            TMA: usr.tma ? formatTime(usr.tma) : '-',
            Avaliacao: usr.avaliacao ? usr.avaliacao : '-',
            TMPR: usr.tmpr ? formatTime(usr.tmpr) : '-',
        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Atendimentos");

        XLSX.writeFile(wb, "relatorio-atendimentos.xlsx");
    };

    return (
        <MainContainer>
            <TicketMessagesDialog
                open={openTicketMessageDialog}

                handleClose={() => setOpenTicketMessageDialog(false)}
                ticketId={ticketId}
            ></TicketMessagesDialog>

            <MainHeader>
                <Title>Relatório de Atendimentos</Title>
            </MainHeader>

            <Paper
                className={classes.filterPaper}
                variant="outlined"
                style={{ padding: '1rem' }}
            >
                <Grid spacing={1} container>
                    <Grid xs={12} sm={6} md={3} item>
                        <TextField
                            type="date"
                            label="Data Inicial"
                            value={dataInicial}
                            onChange={(e) => setDataInicial(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} item>
                        <TextField
                            type="date"
                            label="Data Final"
                            value={dataFinal}
                            onChange={(e) => setDataFinal(e.target.value)}

                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid xs={12} sm={6} md={3} item>
                        <TextField
                            type="text"
                            label="Agente"
                            placeholder="Agente"
                            value={usuario}
                            onChange={(e) => setUsuario(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />

                    </Grid>

                    <Grid xs={12} sm={6} md={1} item>
                        <Button
                            startIcon={<FindInPage />}
                            onClick={() => { getAtendimentoRelatorio() }}
                            color="primary"
                            variant="outlined"
                            title="Filtrar"
                        >
                            FILTRAR
                        </Button>
                    </Grid>

                    <Grid xs={12} sm={6} md={1} item>
                    <Button
                        startIcon={<GetApp />}
                        onClick={exportToXlsx}
                        color="primary"
                        variant="outlined"
                        style={{ marginLeft: '1rem' }}
                        title="Exportar Excel"
                    >
                        Exportar
                    </Button>
                    </Grid>
                </Grid>
            </Paper>
            {/* Table */}
            <Paper
                className={classes.mainPaper}
                variant="outlined"
            >
                {/* <Box style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        startIcon={<GetApp />}
                        onClick={exportToXlsx}
                        color="primary"
                        variant="outlined"
                        style={{ marginBottom: '1rem' }}
                        title="Exportar Excel"
                    >
                        Exportar
                    </Button>
                </Box> */}
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Agente</TableCell>
                            <TableCell align="center">Iniciados</TableCell>
                            <TableCell align="center">Finalizados</TableCell>
                            <TableCell align="center">Enviados para Fila</TableCell>
                            <TableCell align="center">Transferidos</TableCell>
                            <TableCell>TMA</TableCell>
                            <TableCell align="center">Avaliação</TableCell>
                            <TableCell>TMPR</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {!!dataRelatorioFilter && dataRelatorioFilter.length > 0 && dataRelatorioFilter.map((item) => {

                                return (
                                    <>
                                        <TableRow key={item.id}>
                                            <TableCell align="left">{item.nome}</TableCell>
                                            <TableCell align="center">{item.totalabertos ? item.totalabertos : 0}</TableCell>
                                            <TableCell align="center">{item.totalfechados ? item.totalfechados : 0}</TableCell>
                                            <TableCell align="center">{item.totalpendentes ? item.totalpendentes : 0}</TableCell>
                                            <TableCell align="center">{item.transferidos ? item.transferidos : '-'}</TableCell>
                                            <TableCell align="left">{item.tma ? formatTime(item.tma) : '-'}</TableCell>
                                            <TableCell align="center">{item.rating ?
                                                <Rating
                                                    defaultValue={item.rating}
                                                    max={5}
                                                    readOnly
                                                /> :
                                                '-'
                                            }</TableCell>
                                            <TableCell align="left">{item.tmpr ? formatTime(item.tmpr) : '-'}</TableCell>

                                        </TableRow>
                                    </>
                                )
                            })}
                            {load && <TableRowSkeleton columns={8} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer >
    );
};

export default RelatorioAtendimentos;
