import React from "react";
import { Avatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        padding: "10px"
    },
    contactInfo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const VcardPreview = ({ contact, numbers, avatar = "" }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {avatar && <Avatar alt={contact} src={avatar} />}
            {!avatar && <Avatar alt={contact} src="" />}
            <div className={classes.contactInfo}>
                <span>{contact}</span>
                <span>{numbers.replace("+", "")}</span>
            </div>
        </div>
    )
}

export default VcardPreview