import { Box, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

export function QueueFilter({ onFiltered }) {
  const [queue, setQueue] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadQueue();
    }
    fetchData();
  }, []);

  const loadQueue = async () => {
    try {
      const { data } = await api.get(`/queue`);
      setQueue(data);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  return (
    <Autocomplete
      multiple
      size="small"
      options={queue}
      value={selecteds}
      onChange={(e, v, r) => onChange(v)}
      getOptionLabel={(option) => option.name}
      renderQueue={(value, getQueueProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            style={{
              backgroundColor: option.color || "#eee",
              textShadow: "1px 1px 1px #000",
              color: "white",
            }}
            label={option.name}
            {...getQueueProps({ index })}
            size="small"
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Filas"
          variant="outlined"
        />
      )}
    />
  );
}
